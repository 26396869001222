export default {
  locale_key: 'nl-NL',

  today: 'Vandaag',
  yesterday: 'Gisteren',

  morning: 'Goedemorgen',
  afternoon: 'Goedemiddag',
  evening: 'Goedenavond',
  night: 'Goedenacht'
};
