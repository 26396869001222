<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import Link from '@schulinck/components/ui/link/Link.vue';
import Smokebreak from '@schulinck/components/ui/smokebreak/Smokebreak.vue';
import { TagVariants } from '@schulinck/components/ui/tag/Tag.types';
import Tag from '@schulinck/components/ui/tag/Tag.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, ref } from 'vue';

import SourcePaneContentBlock from '@/components/conversation/response/source/SourcePaneContentBlock.vue';
import { EventState, EventType, SourceClickedEventData, SourceSidebarEventData } from '@/models/Event';
import { Source } from '@/models/Message';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useLocaleTranslator } from '@/translations';

import SourceBlock from './SourceBlock.vue';

interface Props {
  sources: Source[];
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();
const showSources = ref(false);
const selectedSourceUsedInAnswer = ref(-1);
const selectedSourceNotUsedInAnswer = ref(-1);
const maxSourcesUnderAnswer = 4;
const sourcesUsedInAnswer = computed(() => props.sources?.filter((source) => source.used_in_answer));
const sourcesNotUsedInAnswer = computed(() => props.sources?.filter((source) => !source.used_in_answer));
const firstFourSources = computed(() => sourcesUsedInAnswer.value.slice(0, maxSourcesUnderAnswer));

const sourceClickedEvent: Event<SourceClickedEventData> = new Event().setType(EventType.SourceClicked);
const sourceSidebarEvent: Event<SourceSidebarEventData> = new Event().setType(EventType.SourceSidebar);

const openSourcesSidebar = (index: number | null, source?: Source) => {
  showSources.value = true;
  if (source?.used_in_answer) {
    selectedSourceUsedInAnswer.value = index ?? -1;
  } else {
    selectedSourceNotUsedInAnswer.value = index ?? -1;
  }

  if (source !== undefined) {
    sourceClickedEvent.setData({
      title: source.title,
      content_type: source.content_type,
      content_id: source.content_id
    });

    dataLayer.push(sourceClickedEvent);
  }

  sourceSidebarEvent.setData({
    state: EventState.Open
  });

  dataLayer.push(sourceSidebarEvent);
};

const closeSourcesSidebar = () => {
  showSources.value = false;

  sourceSidebarEvent.setData({
    state: EventState.Closed
  });

  dataLayer.push(sourceSidebarEvent);
};

window.addEventListener('keyup', (event) => {
  if (event.key === 'Escape') (document.getElementsByClassName('wk-backdrop')[0] as HTMLElement)?.click();
});
</script>

<template>
  <Cluster wrap :gap="0">
    <Cluster class="source-blocks-header" :alignItems="Position.Center" :gap="0.5">
      <Icon :name="IconNames.ListBulletSquare" />
      <Typography :variant="TypographyVariants.H4" noMargin>{{ translate('sources.title') }}</Typography>
      <Tag :variant="TagVariants.BlueTint">{{ sourcesUsedInAnswer.length }} </Tag>
    </Cluster>
    <Link class="source-blocks-more-sources-link" @click="openSourcesSidebar(null)">
      {{ translate('sources.sources_link') }}
      <Icon :name="IconNames.ArrowRight" />
    </Link>
    <Cluster class="source-blocks-container" :alignItems="Position.Stretch">
      <SourceBlock
        v-for="(source, index) in firstFourSources"
        :key="index"
        :source="source"
        @click="openSourcesSidebar(index, source)"
      />
    </Cluster>
  </Cluster>

  <Smokebreak :show="showSources" :onClose="closeSourcesSidebar">
    <SourcePaneContentBlock
      v-if="sourcesUsedInAnswer.length != 0"
      :title="translate('sources.title')"
      :subtitle="translate('sources.used_in_answer')"
      :sources="sourcesUsedInAnswer"
      :selectedSource="selectedSourceUsedInAnswer"
    />
    <SourcePaneContentBlock
      v-if="sourcesNotUsedInAnswer.length != 0"
      class="related-info"
      :title="translate('sources.related_information')"
      :subtitle="translate('sources.related_information_subtitle')"
      :sources="sourcesNotUsedInAnswer"
      :selectedSource="selectedSourceNotUsedInAnswer"
    />
  </Smokebreak>
</template>

<style scoped lang="scss">
.source-blocks-header {
  padding: 0.5rem 0 0.75rem 0;
  order: 1;
  width: 100%;
  h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }

  :deep(.wk-tag-blue-tint) {
    background-color: $wk-blue-tint5;
  }

  @include mq(xs) {
    width: auto;
  }
}

.source-blocks-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 0.5rem;
  order: 2;

  & > div {
    width: 100%;
  }

  @include mq(sm) {
    width: calc(100% - 0.5rem);
    grid-template-columns: repeat(2, 50%);
    order: 3;
  }
}

.source-blocks-more-sources-link {
  padding: 0.5rem 0 0.75rem 0;
  margin-left: auto;
  color: $wk-blue-shade1;
  order: 3;
  > i {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    text-decoration: none;

    > i {
      padding-left: 0.25rem;
    }
  }

  @include mq(xs) {
    order: 2;
  }
}

.related-info {
  margin: 1.5rem 0;

  @include mq(sm) {
    margin: 2.5rem 0;
  }
}
</style>
