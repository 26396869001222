export default {
  'filter.placeholder': 'Zoeken in je historie...',
  'filter.noResults': 'Geen resultaten gevonden',

  'newThread.title': 'Nieuwe opdracht',

  'contact.label': 'Contact met Schulinck',

  'thread.menu.share': 'Link kopieren',
  'thread.menu.delete': 'Verwijderen',

  'thread.menu.copy.notification.success.title': 'Kopieren geslaagd',
  'thread.menu.copy.notification.success.content': 'De link is succesvol gekopieerd',
  'thread.menu.copy.notification.failed.title': 'Kopieren mislukt',
  'thread.menu.copy.notification.failed.content': 'De link kon niet gekopieerd worden',

  'thread.menu.confirmation.title': 'Weet je het zeker?',
  'thread.menu.confirmation.text': 'Weet je zeker dat je deze vraag wilt verwijderen?',
  'thread.menu.confirmation.cancel': 'Annuleren',
  'thread.menu.confirmation.confirm': 'Verwijderen',

  'thread.menu.delete.notification.success.title': 'Verwijderen geslaagd',
  'thread.menu.delete.notification.success.content': 'De vraag is succesvol verwijderd',
  'thread.menu.delete.notification.failed.title': 'Verwijderen mislukt',
  'thread.menu.delete.notification.failed.content': 'De vraag kon niet verwijderd worden'
};
