<script setup lang="ts">
import { Colors } from '@schulinck/components/theme/color';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed } from 'vue';

import { formatDate } from '@/services/utils/date';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

interface Props {
  loading: boolean;
  domain: string;
  createdAt: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  domain: '',
  createdAt: null
});

const createdAt = computed(() => {
  if (props.createdAt === null) return 'Unknown Date';
  return formatDate(new Date(props.createdAt * 1000));
});
</script>

<template>
  <Typography
    v-if="loading"
    :variant="TypographyVariants.Paragraph"
    :color="Colors.Gray"
    class="conversation-meta loading"
  >
    {{ translate('conversation.meta.loading') }}
  </Typography>
  <Typography v-else :variant="TypographyVariants.Paragraph" :color="Colors.Gray" class="conversation-meta">
    {{ domain }} &middot; {{ createdAt }}
  </Typography>
</template>

<style scoped lang="scss">
.conversation-meta {
  font-size: 14px;

  &.loading {
    animation: pulse 2s ease-in-out infinite;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}
</style>
