import { createGtm } from '@gtm-support/vue-gtm';
import { App } from 'vue';
import { Router } from 'vue-router';

export const initGtm = (app: App, router: Router): void => {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID || 'GTM-XXXXXX',
      vueRouter: router
    })
  );
};
