export default {
  singleSource: 'bron',
  sources: 'bronnen',
  sources_link: 'Bronnen bekijken',
  readMore: 'Meer Lezen',
  readLess: 'Minder Lezen',
  goToFullSource: 'Naar volledige kennisbank pagina',
  title: 'Bronnen',
  used_in_answer: 'Informatie gebruikt voor het antwoord',
  related_information: 'Gerelateerde informatie',
  related_information_subtitle: 'Aanvullende informatie bij het onderwerp'
};
