import * as Sentry from '@sentry/vue';
import { App } from 'vue';

export const initSentry = (app: App): void => {
  if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'dev') {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL]
    });
  }
};
