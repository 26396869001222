<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { AxiosError } from 'axios';
import { ref } from 'vue';

import { ErrorReturnType } from '@/models/Error';
import { EventType, ResponseFeedbackEventData } from '@/models/Event';
import { FeedbackFormValues, FeedbackValue, ThumbButtonsState, ThumbFeedbackType } from '@/models/Feedback';
import { MessageInterface } from '@/models/Message';
import { handleApiError, sa_api } from '@/services/api/api';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';

import FeedbackForm from './form/FeedbackForm.vue';
import ThumbsDown from './ThumbsDown.vue';
import ThumbsUp from './ThumbsUp.vue';

interface Props {
  enableForm?: boolean;
  message: MessageInterface;
}

interface InputInterface {
  score_type: string;
  score_value: string;
  score_comment: string | null;
  trace_id: string;
}

const props = withDefaults(defineProps<Props>(), {
  enableForm: true
});

const activeButton = ref<ThumbButtonsState>(ThumbButtonsState.None);
const thumbsDownReference = ref<HTMLElement>();
const showFeedbackForm = ref(false);
const responseFeedbackEvent: Event<ResponseFeedbackEventData> = new Event().setType(EventType.AnswerFeedback);

const handleButtonClick = (button: ThumbButtonsState) => {
  activeButton.value = button;
  sendEventToAnalytics(button);

  if (button === ThumbButtonsState.Up) {
    sendDataToBackend(FeedbackValue[activeButton.value], null);
    showFeedbackForm.value = false;
  } else if (button === ThumbButtonsState.Down) {
    showFeedbackForm.value = true;
  } else {
    showFeedbackForm.value = false;
  }

  return false;
};

const sendEventToAnalytics = (button: ThumbButtonsState) => {
  responseFeedbackEvent.setData({
    trace_id: props.message.trace_id,
    value: FeedbackValue[button]
  });
  dataLayer.push(responseFeedbackEvent);
};

const sendDataToBackend = async (scoreValue: string, formValues: FeedbackFormValues | null) => {
  const input: InputInterface = {
    score_type: ThumbFeedbackType.Answer,
    score_value: scoreValue,
    score_comment: formValues ? (formValues.text as string) : null,
    trace_id: props.message.trace_id
  };

  await sa_api
    .post('/chat/user-feedback', { input: input })
    .then(() => {
      return false;
    })
    .catch((error: AxiosError) => {
      handleApiError(error, ErrorReturnType.Notification);
    });

  return false;
};

const onHideFeedbackForm = () => {
  showFeedbackForm.value = false;
};

const onSubmitFeedbackForm = (formValues: FeedbackFormValues) => {
  showFeedbackForm.value = false;
  sendDataToBackend(FeedbackValue[ThumbButtonsState.Down], formValues);
};
</script>

<template>
  <Stack :gap="0">
    <Cluster class="feedback" :gap="0">
      <Typography class="label" :variant="TypographyVariants.Paragraph">
        <slot />
      </Typography>
      <ThumbsUp :clickedButton="activeButton" :onButtonClicked="handleButtonClick" />
      <ThumbsDown
        ref="thumbsDownReference"
        :clickedButton="activeButton"
        :focussed="showFeedbackForm"
        :onButtonClicked="handleButtonClick"
      />
    </Cluster>
    <FeedbackForm
      :show="showFeedbackForm"
      :onHide="onHideFeedbackForm"
      :onSubmit="onSubmitFeedbackForm"
      :reference="thumbsDownReference"
      :traceId="props.message.trace_id"
    />
  </Stack>
</template>

<style scoped lang="scss">
.feedback {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  color: $wk-gray-tint1;
  position: relative;

  @include mq(xs) {
    padding-bottom: 0.5rem;
  }

  .label {
    margin-right: 0.5rem;
  }
}
</style>
