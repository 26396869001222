import { useLocaleTranslator } from '@/translations';
const { translate } = useLocaleTranslator();

const isInRange = (x: number, min: number, max: number) => {
  return x >= min && x < max;
};

const format: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  timeZone: 'Europe/Amsterdam'
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString(translate('date.locale_key'), format);
};

export const getTimeOfDayGreeting = () => {
  const currentHour = new Date().getHours();
  if (isInRange(currentHour, 5, 12)) return translate('date.morning');
  if (isInRange(currentHour, 12, 18)) return translate('date.afternoon');
  if (isInRange(currentHour, 18, 24)) return translate('date.evening');
  if (isInRange(currentHour, 0, 5)) return translate('date.night');
};

export const getConvertDateToHumanWords = (date: string) => {
  const today = new Date();
  if (date === formatDate(today)) return translate('date.today');

  today.setDate(today.getDate() - 1);
  if (date === formatDate(today)) return translate('date.yesterday');

  return date;
};

export const getCurrentUnixTimestamp = () => Math.floor(Date.now() / 1000);
