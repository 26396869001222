<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import Link from '@schulinck/components/ui/link/Link.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import { Breadcrumb, breadcrumbsIcons } from '@/models/Breadcrumbs';
import { ContentType } from '@/models/Message';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

interface Props {
  contentType: ContentType;
  breadcrumbs?: Breadcrumb[] | null;
  readonly?: boolean;
}

defineProps<Props>();
</script>

<template>
  <div :class="['wk-source-breadcrumbs', readonly && 'readonly']">
    <Cluster :wrap="!readonly" :alignItems="Position.Center" :gap="0.2" class="type">
      <Cluster :alignItems="Position.Center" :gap="0.2" class="content-type">
        <Icon :name="breadcrumbsIcons[contentType]" />
        <Typography :variant="TypographyVariants.Paragraph" noMargin>
          {{ translate(`contentType.${contentType}`) }}
        </Typography>
        <Icon v-if="breadcrumbs && breadcrumbs.length > 0" :name="IconNames.ChevronRight" :size="0.75" />
      </Cluster>
      <Cluster
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.title"
        :alignItems="Position.Center"
        :gap="0.375"
      >
        <Link target="_blank" :inherit="true" :href="breadcrumb.permalink">{{ breadcrumb.title }}</Link>
        <Icon v-if="breadcrumbs && index !== breadcrumbs.length - 1" :name="IconNames.ChevronRight" :size="0.75" />
      </Cluster>
    </Cluster>
  </div>
</template>

<style scoped lang="scss">
.wk-source-breadcrumbs {
  max-width: 100%;
  position: relative;
  &.readonly {
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 3rem;
      height: 100%;
      background: linear-gradient(to right, rgba($wk-blue-tint6, 0), #f8fafd 100%);
      pointer-events: none;
    }
    .type {
      pointer-events: none;
      width: 2000%;
    }
  }
}

.content-type {
  cursor: auto;
}

.type {
  margin-top: 0;
  font-size: 0.8125rem;
  line-height: 1.625rem;
  color: $wk-gray-tint1;

  .wk-icon {
    margin: 0;
  }
}
</style>
