<script setup lang="ts">
import { ButtonVariants } from '@schulinck/components/ui/button/Button.types';
import Button from '@schulinck/components/ui/button/Button.vue';
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { onMounted, ref } from 'vue';

import SimpleModal from '@/components/layout/SimpleModal.vue';
import { NotificationType } from '@/models/Notification';
import { Permission } from '@/models/PermissionSet';
import { Thread } from '@/models/Threads';
import { useNotificationStore } from '@/stores/notification';
import { useThreadsStore } from '@/stores/threads';
import { useLocaleTranslator } from '@/translations';

import AnimatedDeleteIcon from './Icons/AnimatedDeleteIcon.vue';
import AnimatedShareIcon from './Icons/AnimatedShareIcon.vue';

interface Props {
  thread: Thread;
  hideMenu: () => void;
}

const props = defineProps<Props>();

const showDeleteConfirmDialog = ref(false);
const listScrollPos = ref('');
const threadsStore = useThreadsStore();
const { translate } = useLocaleTranslator();
const resultString = (result: boolean) => (result ? 'success' : 'failed');

const shareThread = (thread: Thread) => {
  let result = true;
  try {
    navigator.clipboard.writeText(`${document.location.host}/chat/${thread.thread_uuid}`);
  } catch (e) {
    result = false;
    console.warn("Can't use clipboard in dev environment because of unsecure connection (http)");
  }
  useNotificationStore().showNotification(
    result ? NotificationType.Success : NotificationType.Warning,
    translate(`sidebar.thread.menu.copy.notification.${resultString(result)}.title`),
    translate(`sidebar.thread.menu.copy.notification.${resultString(result)}.content`),
    2500
  );
  props.hideMenu();
};
const cancelDeleteThread = () => {
  showDeleteConfirmDialog.value = false;
  props.hideMenu();
};
const deleteThread = () => {
  const result = true;
  // some call to backend to delete thread using props.thread.thread_uuid

  // on success:
  threadsStore.deleteThread(props.thread.thread_uuid);
  showDeleteConfirmDialog.value = false;

  // on failure:
  // result = false;

  useNotificationStore().showNotification(
    NotificationType.Success,
    translate(`sidebar.thread.menu.delete.notification.${resultString(result)}.title`),
    translate(`sidebar.thread.menu.delete.notification.${resultString(result)}.content`),
    2500
  );
  props.hideMenu();
};

onMounted(() => {
  const listEl = document.querySelector('.wk-sidebar .threads-list .list') as HTMLElement;
  if (!listEl) return;

  listScrollPos.value = -listEl.scrollTop + 'px';
  (listEl as HTMLElement).addEventListener('scroll', () => {
    listScrollPos.value = `${-listEl.scrollTop}px`;
  });
});
</script>

<template>
  <div class="thread-list-item-menu">
    <Clickable class="thread-list-item-menu-button" @click="shareThread(thread)">
      <AnimatedShareIcon />
      {{ translate('sidebar.thread.menu.share') }}
    </Clickable>
    <Clickable
      v-if="$hasPermission(Permission.ThreadDeleteUser)"
      class="thread-list-item-menu-button delete"
      @click="() => (showDeleteConfirmDialog = true)"
    >
      <AnimatedDeleteIcon />
      {{ translate('sidebar.thread.menu.delete') }}
    </Clickable>
    <SimpleModal :show="showDeleteConfirmDialog">
      <template #title>{{ translate('sidebar.thread.menu.confirmation.title') }}</template>
      <template #body> {{ translate('sidebar.thread.menu.confirmation.text') }}</template>
      <template #footer>
        <Button :variant="ButtonVariants.Text" :onClick="cancelDeleteThread">
          {{ translate('sidebar.thread.menu.confirmation.cancel') }}
        </Button>
        <Button :onClick="deleteThread" danger>
          <Icon :name="IconNames.Trash" />
          {{ translate('sidebar.thread.menu.confirmation.confirm') }}
        </Button>
      </template>
    </SimpleModal>
  </div>
</template>

<style scoped lang="scss">
.thread-list-item-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  margin-left: -0.5rem;
  margin-top: v-bind(listScrollPos);
  width: max-content;
  background-color: $wk-white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  outline: solid 1px $wk-gray-tint4;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  z-index: 9999;

  @include mq(xs, max) {
    left: unset;
    right: 2rem;
  }

  .thread-list-item-menu-button {
    padding: 0.5rem 1rem;
    width: 100%;
    font-weight: normal;

    display: inline-flex;
    gap: 0.25rem;
    align-items: center;

    &:hover {
      color: $wk-blue-shade1;
      background-color: $wk-blue-tint6;
    }

    &.delete {
      color: $wk-red;
    }
  }
}
</style>
