<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import Conversation from '@/components/conversation/Conversation.vue';
import LargePrompt from '@/components/conversation/prompt/LargePrompt.vue';
import Disclaimer from '@/components/Disclaimer.vue';
import Greeting from '@/components/introduction/Greeting.vue';
import FirstLoginPopup from '@/components/layout/FirstLoginPopup.vue';
import MainLayout from '@/components/layout/MainLayout.vue';
import { useThreadStore } from '@/stores/thread';
import FadeTransition from '@/transitions/FadeTransition.vue';

const threadStore = useThreadStore();
const route = useRoute();
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
  route.params.thread_uuid && threadStore.fetchMessages(route.params.thread_uuid);
});

const showForm = computed(() => isMounted.value && !threadStore.uuid && !threadStore.loading);
watchEffect(() => threadStore.threadUuid && threadStore.fetchMessages(threadStore.threadUuid));
</script>

<template>
  <Suspense>
    <MainLayout :showFooter="showForm">
      <FadeTransition :duration="250" mode="out-in">
        <Stack v-if="showForm" class="prompt-container" :justifyContent="Position.Center" :gap="0">
          <Greeting />
          <LargePrompt />
          <Disclaimer />
        </Stack>
        <div v-else-if="!isMounted">&nbsp;</div>
        <Conversation v-else />
      </FadeTransition>
      <FirstLoginPopup />
    </MainLayout>
    <template #fallback> Loading... </template>
  </Suspense>
</template>

<style lang="scss" scoped>
.prompt-container {
  margin-top: 25%;
  margin-bottom: auto;

  @include mq(sm) {
    padding-bottom: 20vh;
    margin-top: auto;
  }
}
</style>
