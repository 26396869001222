<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { onMounted } from 'vue';

import Notification from '@/components/conversation/response/feedback/Notification.vue';
import { EventNoData, EventType } from '@/models/Event';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useNotificationStore } from '@/stores/notification';
import FadeTransition from '@/transitions/FadeTransition.vue';

import Footer from './footer/Footer.vue';
import Header from './header/Header.vue';
import Sidebar from './sidebar/Sidebar.vue';

const notificationStore = useNotificationStore();

interface Props {
  showFooter: boolean;
}
defineProps<Props>();

const applicationInitializedEvent: Event<EventNoData> = new Event().setType(EventType.ApplicationInitialized);

onMounted(() => {
  dataLayer.push(applicationInitializedEvent);
});
</script>

<template>
  <Cluster :gap="0">
    <Sidebar />
    <Stack class="wk-a-body">
      <Header />
      <Notification
        v-if="notificationStore.visible"
        :message="notificationStore.message"
        :onClose="notificationStore.hideNotification"
        @click="notificationStore.hideNotification"
      />
      <Stack class="wk-a-main" :gap="0">
        <slot></slot>
      </Stack>
      <FadeTransition :duration="300">
        <Footer v-if="showFooter" />
      </FadeTransition>
    </Stack>
  </Cluster>
</template>

<style scoped lang="scss">
.wk-a-body {
  min-height: 100vh;
  width: 100%;
}

.wk-a-main {
  position: relative;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  align-self: center;
  padding: 0.5rem 1rem;
  min-height: 100vh;

  @include mq(sm) {
    min-height: unset;
  }

  @include mq(md) {
    padding: 0;
  }
}

:deep(.wk-split-pane-container) {
  position: sticky;
  top: 0;
  .wk-split-pane-left-heading {
    justify-content: flex-start !important;
  }

  .wk-split-pane-left {
    min-width: 3rem;
    background-color: $wk-white;
    .wk-split-pane-toggle-button {
      margin: 0;
    }

    &:not(.wk-split-pane-left-hidden) {
      .wk-split-pane-toggle-button {
        padding: 0;
      }
    }
  }

  .wk-split-pane-right {
    visibility: hidden;
  }

  .wk-split-pane-grip {
    display: none;
  }
}
</style>
