export default {
  frequently_asked_question: 'Veelgestelde vragen',
  guideline: 'Beleid',
  municipal_document: 'Gemeentelijk bijlagen',
  regulation_element: 'Wetteksten',
  thematic_immersion: "Thema's",
  verdict: 'Rechtspraak',
  verdict_annotation: 'Rechtspraak',
  jira_comment: 'Helpdesk'
};
