<script async setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';

import NotFound from '@/components/layout/NotFound.vue';
import FilterInputField from '@/components/layout/sidebar/FilterInputField.vue';
import { Breakpoint } from '@/models/Breakpoint';
import { Thread } from '@/models/Threads';
import { formatDate, getConvertDateToHumanWords } from '@/services/utils/date';
import { useThreadStore } from '@/stores/thread';
import { useThreadsStore } from '@/stores/threads';
import { useLocaleTranslator } from '@/translations';

import ThreadsListItem from './ThreadsListItem.vue';

interface Props {
  show: boolean;
  toggleSidebar: () => void;
}
const props = defineProps<Props>();

const { translate } = useLocaleTranslator();
const threadsStore = useThreadsStore();
const threadStore = useThreadStore();
const filterQuery = ref('');
const listEl = ref();

const activeThreadId = computed(() => threadStore.uuid);

const sortedThreads: ComputedRef<Thread[] | null> = computed(() => {
  const query = filterQuery.value.toLowerCase();
  return [...threadsStore.threads]
    .filter((t: Thread) => t.title.toLowerCase().includes(query))
    .sort((a: Thread, b: Thread) => (b.updated_at <= a.updated_at ? -1 : 1))
    .map((thread: Thread) => ({ ...thread, updated_at: formatDate(new Date((thread.updated_at as number) * 1000)) }));
});

const groupedThreads = computed<Partial<Record<string, Thread[]>> | null>(
  () => sortedThreads.value && Object.groupBy(sortedThreads.value, ({ updated_at }) => updated_at)
);

const isThreadActive = (thread: Thread) => thread.thread_uuid === activeThreadId.value;
const updateFilter = (value: string) => (filterQuery.value = value);
const threadClicked = (thread: Thread) => {
  if (!isThreadActive(thread)) threadStore.openThread(thread.thread_uuid);
  if (window.innerWidth < Breakpoint.Xs) props.toggleSidebar();
};

onMounted(async () => await threadsStore.fetchThreads());
</script>

<template>
  <Stack :class="['threads-list', show && 'visible']" :gap="1.5">
    <FilterInputField :query="filterQuery" :onUpdate="updateFilter" />
    <Stack ref="listEl" class="list">
      <TransitionGroup name="list">
        <div v-for="(threadsGroup, updated_at) in groupedThreads" :key="updated_at" class="group">
          <p class="threads-group-header">{{ getConvertDateToHumanWords(updated_at) }}</p>
          <Stack :gap="0">
            <ThreadsListItem
              v-for="thread in threadsGroup"
              :key="thread.thread_uuid"
              :thread="thread"
              :active="isThreadActive(thread)"
              :onThreadClicked="threadClicked"
            />
          </Stack>
        </div>
      </TransitionGroup>
      <NotFound v-if="sortedThreads?.length === 0" :title="translate('sidebar.filter.noResults')" />
    </Stack>
  </Stack>
</template>

<style scoped lang="scss">
.threads-list {
  width: 100%;
  padding-top: 1rem;
  transition: all 1s ease-in-out;
  overflow: hidden;

  .list {
    width: 100%;
    padding-bottom: 0.5rem;
    border: solid 1px transparent; // prevent layout jump when opening
    opacity: 0;
    transition: opacity 0.1s ease-in-out 0s;

    .wk-not-found {
      width: 40%;
      align-self: center;
      margin-top: 2rem;

      :deep(.wk-typography-h4) {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  .filter {
    margin-left: 0.75rem;
  }

  .threads-group-header {
    color: $wk-gray-tint1;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem 0 0.75rem;
  }

  &.visible {
    .filter,
    .list {
      width: 100%;
      opacity: 1;
      transition: opacity 0.3s ease-in-out 0.3s;
    }

    .list {
      overflow-y: auto;
      height: auto;
    }

    .filter {
      width: calc(100% - 1.5rem);
    }

    :deep(.threads-list-item) {
      .wk-clickable {
        width: 100%;
      }

      &.thread-menu-button .wk-icon {
        color: $wk-gray-tint1;
        width: auto;
        transform: rotate(0.25turn);
        transform-origin: center center;
      }
    }
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.wk-tooltip {
  max-width: unset !important;
}
</style>
