<script setup lang="ts">
import { Colors } from '@schulinck/components/theme/color';
import Hr from '@schulinck/components/ui/hr/Hr.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import MainLayout from '@/components/layout/MainLayout.vue';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { useLocaleTranslator } from '@/translations';

const version = RELEASE_VERSION;

const { translate } = useLocaleTranslator();

useSetPageTitle(translate('pageTitle.about'));
</script>

<template>
  <Suspense>
    <MainLayout showFooter class="about-page">
      <Typography :variant="TypographyVariants.H1">{{ translate('about.title1') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('about.p1')"></div></Typography>
      <Typography :variant="TypographyVariants.H4" noMargin>{{ translate('about.title2') }} </Typography>
      <Typography :variant="TypographyVariants.Paragraph"> <div v-html="translate('about.p2')"></div> </Typography>
      <Typography :variant="TypographyVariants.H4" noMargin>{{ translate('about.title3') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"> <div v-html="translate('about.p3')"></div> </Typography>
      <Typography :variant="TypographyVariants.H4" noMargin>{{ translate('about.title4') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('about.p4')"></div></Typography>

      <Typography :variant="TypographyVariants.H3">{{ translate('about.title5') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('about.p5')"></div></Typography>
      <Typography :variant="TypographyVariants.H4" noMargin>{{ translate('about.title6') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('about.p6')"></div></Typography>
      <Typography :variant="TypographyVariants.H4">{{ translate('about.title7') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('about.p7')"></div></Typography>
      <Hr />
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray">
        {{ translate('about.version', { version }) }}
      </Typography>
    </MainLayout>
  </Suspense>
</template>

<style scoped lang="scss">
.about-page {
  :deep(.wk-typography-h1) {
    margin-bottom: 0.5rem;
  }
  :deep(.wk-typography-h2) {
    margin-top: 3rem;
  }

  :deep(.wk-typography-p) {
    ol,
    ul,
    menu {
      list-style-type: disc;
      margin: 0 1rem;
      padding: 1rem;
    }

    ol {
      list-style-type: decimal;

      li {
        margin-bottom: 0.5rem;
      }
    }

    a {
      color: $wk-blue;
    }
  }
}
</style>
