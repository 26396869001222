export default {
  cancel: 'Annuleren',
  submit: 'Verzenden',
  close: 'Sluiten',

  'application.title': 'Schulinck Assistent',

  'auth.initiating': 'Bezig met inloggen...',

  'greeting.subtitle': 'Waar kan Schulinck Assistent je mee helpen?',

  'disclaimer.message':
    'Schulinck Assistent is in beta en kan fouten maken. Controleer <strong>altijd</strong> belangrijke informatie.',
  'disclaimer.linkText': 'Meer lezen over de beta.',

  'domaintooltip.title': 'Selecteer hier je vakgebied',
  'domaintooltip.info': 'Vul hier je gebruikelijke vakgebied in zodat de assistent je beter begrijpt.',
  'domaintooltip.be_aware': 'Let op: het gekozen vakgebied wordt opgeslagen maar kun je altijd veranderen.',

  '404.title': '404 - Deze pagina bestaat niet',
  '404.p1': 'We konden deze pagina helaas niet vinden. Controleer de link op typefouten of',
  '404.go_to_home': 'ga naar de homepagina',

  '403.title': '403 - Je hebt geen toegang tot deze pagina',
  '500.title': '500 - Er is iets misgegaan, probeer het later opnieuw'
};
