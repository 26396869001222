<script async setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Position } from '@schulinck/components/theme/position';
import Pill from '@schulinck/components/ui/pill/Pill.vue';
import { ComponentPublicInstance, computed, ComputedRef, onUnmounted, ref, watch } from 'vue';

import logo from '@/assets/header-logo.svg';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { UserDetailsDataInterface } from '@/models/UserDetails';
import router from '@/router';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';

import ProfileMenu from './profileMenu/ProfileMenu.vue';

const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), {
  threshold: [1]
});

onUnmounted(() => {
  if (headerElement.value) observer.unobserve(headerElement.value?.$el);
});

const userStore = useUserStore();
const threadStore = useThreadStore();
const userDetails: ComputedRef<UserDetailsDataInterface | null> = computed(() => userStore.userDetails);

const headerElement = ref<ComponentPublicInstance>();

watch(headerElement, (header) => {
  if (header) observer.observe(header?.$el);
});

const goToHome = () => {
  threadStore.resetThread();
  router.push({ name: 'home' });
  useSetPageTitle();
};
</script>

<template>
  <Cluster ref="headerElement" class="wk-a-header" :justifyContent="Position.Between">
    <div class="wk-logo-wrapper" @click="goToHome">
      <img class="logo" :src="logo" alt="" /><Pill><template #text>Beta</template></Pill>
    </div>
    <div v-if="userDetails" class="wk-a-fish">
      <ProfileMenu />
    </div>
  </Cluster>
</template>

<style scoped lang="scss">
.wk-a-header {
  background-color: $wk-white;
  height: 5rem;
  padding: 0.5rem 1rem 0.5rem 5rem;
  position: sticky;
  top: -1px; /* needed for intersection observer */
  width: 100%;
  z-index: map-get($zIndexes, 'sticky-layout');
  transition: box-shadow 300ms ease-in-out;

  @include mq(xs) {
    height: 4rem;
    padding: 0.5rem 2rem;
    align-items: center;
    width: 100%;
  }

  &.is-pinned {
    padding-top: calc(0.5rem + 2px);
    box-shadow: 0 4px 8px 0 rgba($wk-black, 0.1);
  }

  .wk-logo-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: 0.5rem;

    @include mq(xs) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      top: unset;
    }

    .logo {
      height: 34px;
    }

    .wk-pill {
      background-color: $wk-blue-shade1;
      color: $wk-white;
      padding: 0 0.5rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.6875rem;
      line-height: 1rem;
      letter-spacing: 1px;

      @include mq(xs) {
        margin-left: 1rem;
      }
    }
  }

  .wk-a-fish {
    margin-left: auto;
  }
}
</style>
