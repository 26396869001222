import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

const truncatePageTitle = (str: string, maxWords: number = 6): string => {
  const words = str.split(' ');
  if (words.length <= maxWords) {
    return str;
  }
  return words.slice(0, maxWords).join(' ');
};

const getPageTitle = (dynamicTitle: string | undefined): string => {
  const appTitle = translate('application.title');
  return dynamicTitle ? `${truncatePageTitle(dynamicTitle)} | ${appTitle}` : appTitle;
};

export const useSetPageTitle = (dynamicTitle: string | undefined = undefined): void => {
  document.title = getPageTitle(dynamicTitle);
};
