import { App } from 'vue';
import VueGtag from 'vue-gtag';

export const initGtag = (app: App, appName: string): void => {
  app.use(VueGtag, {
    appName,
    config: {
      id: import.meta.env.VITE_GA_ID,
      params: {
        anonymize_ip: true
      }
    }
  });
};
