<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="dot1" cx="2.80519" cy="8" r="2.24416" stroke="#353535" stroke-width="1.12208" />
    <g class="connection1">
      <rect
        class="line1"
        x="4.59924"
        y="5.32027"
        width="7.05455"
        height="1.03896"
        transform="rotate(-30)"
        fill="#353535"
      />
      <circle class="dot2" cx="13.1948" cy="2.80516" r="2.24416" stroke="#353535" stroke-width="1.12208" />
    </g>

    <g class="connection2">
      <rect
        class="line2"
        x="5.07092"
        y="9.31169"
        width="7.05455"
        height="1.03896"
        fill="#353535"
        transform="rotate(30)"
      />
      <circle class="dot3" cx="13.1948" cy="13.1948" r="2.24416" stroke="#353535" stroke-width="1.12208" />
    </g>
  </svg>
</template>

<style scoped lang="scss">
svg {
  display: inline-block;
  margin-left: 0.2em;
  margin-right: 0.2em;
  width: 1em;

  .line1 {
    transform-origin: 40% 40%;
  }
  .line2 {
    transform-origin: 40% 60%;
  }

  .dot2 {
    transform-origin: 82% 18%;
  }
  .dot3 {
    transform-origin: 82% 82%;
  }

  .thread-list-item-menu-button:hover & {
    .line1,
    .dot2 {
      animation: conn1 1s ease-in-out 0.2s forwards;
    }
    .line2,
    .dot3 {
      animation: conn2 1s ease-in-out 0.2s forwards;
    }
  }
}

@keyframes conn1 {
  0% {
    transform: rotate(-30deg) scale(1);
  }
  25%,
  45% {
    transform: rotate(-30deg) scale(0);
  }
  70%,
  100% {
    transform: rotate(-30deg) scale(1);
  }
}

@keyframes conn2 {
  0% {
    transform: rotate(30deg) scale(1);
  }
  25%,
  75% {
    transform: rotate(30deg) scale(0);
  }
  100% {
    transform: rotate(30deg) scale(1);
  }
}
</style>
