<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="bucket">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.11696 3.11688H2.078V15.5844H3.11696V3.11688Z"
        fill="#E5202E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.23391 5.19481H5.19495V12.4675H6.23391V5.19481Z"
        fill="#E5202E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.31179 5.19481H7.27283V12.4675H8.31179V5.19481Z"
        fill="#E5202E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3897 5.19481H9.35071V12.4675H10.3897V5.19481Z"
        fill="#E5202E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5066 3.11688H12.4677V15.5844H13.5066V3.11688Z"
        fill="#E5202E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5066 15.5844V14.5455H2.07802V15.5844H13.5066Z"
        fill="#E5202E"
      />
    </g>
    <g class="lid">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5455 3.11687V2.07791H1.03904V3.11687H14.5455Z"
        fill="#E5202E"
      />
      <path
        d="M10.3898 2.5974H10.9092V2.07792C10.9092 1.10442 9.99431 0.519475 9.09105 0.519475H6.49365C5.59039 0.519475 4.67547 1.10442 4.67547 2.07792V2.5974H5.19495H10.3898Z"
        stroke="#E5202E"
        stroke-width="1.03896"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
svg {
  display: inline-block;
  margin-left: 0.2em;
  margin-right: 0.2em;
  width: 1em;
  overflow: visible;

  .thread-list-item-menu-button:hover & {
    .bucket {
      transform-origin: center right;
      animation: toppleDown 0.4s ease-in-out 0.3s forwards;
    }
    .lid {
      transform-origin: center right;
      animation: toppleUp 0.4s ease-in-out 0.3s forwards;
    }
  }
}

@keyframes toppleUp {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(13deg);
  }
}

@keyframes toppleDown {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-13deg);
  }
}
</style>
