<script setup lang="ts">
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { TooltipColor, TooltipPosition } from '@schulinck/components/ui/tooltip/Tooltip.types';
import Tooltip from '@schulinck/components/ui/tooltip/Tooltip.vue';
import { ref } from 'vue';

interface Props {
  preventSubmit: boolean;
  submitPrompt: (event: KeyboardEvent) => void;
  tooltipTitle: string;
  submitButtonIcon: IconNames;
}

const props = defineProps<Props>();
const submitClicked = ref<boolean>(false);

const handleSubmit = (event: KeyboardEvent) => {
  submitClicked.value = true;
  props.submitPrompt(event);
};
</script>

<template>
  <Tooltip
    v-if="preventSubmit && submitClicked"
    :title="tooltipTitle"
    :position="TooltipPosition.Bottom"
    :backgroundColor="TooltipColor.Orange"
  >
    <button type="submit" :onclick="handleSubmit">
      <Icon :name="submitButtonIcon" />
    </button>
  </Tooltip>
  <button v-else type="submit" :onclick="handleSubmit">
    <Icon :name="submitButtonIcon" />
  </button>
</template>

<style scoped lang="scss">
button[type='submit'] {
  @include gradient-background;
  color: $wk-white;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  font-size: 1.0625rem;
}
</style>
