<script setup lang="ts">
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { computed, ref, watch } from 'vue';

import { EventNoData, EventType } from '@/models/Event';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useThreadStore } from '@/stores/thread';
import { useLocaleTranslator } from '@/translations';

import GrowingTextarea from './GrowingTextarea.vue';
import SubmitButtonWithTooltip from './SubmitButtonWithTooltip.vue';

const { translate } = useLocaleTranslator();
const threadStore = useThreadStore();

const maxPromptLength = 2000;
const inputValue = ref<string>('');
const inputElement = ref<HTMLElement>();
const promptLengthExceededEvent: Event<EventNoData> = new Event().setType(EventType.PromptLengthExceeded);

defineExpose({ inputElement });
const isResponseLoading = computed(() => threadStore.loading);
const promptLengthExceeded = computed(() => inputValue.value.length > maxPromptLength);
const preventSubmit = computed(() => inputValue.value.length === 0 || promptLengthExceeded.value);
const enableShakeClass = ref<boolean>(false);

const tooltipTitle = computed(() => {
  if (promptLengthExceeded.value) {
    return translate('prompt.small.length_exceeded');
  }
  return preventSubmit.value ? translate('prompt.small.fill_in_question') : '';
});

const handleInputChange = (value: string) => (inputValue.value = value);
const submitPrompt = (event: KeyboardEvent) => {
  event.preventDefault();

  if (preventSubmit.value) {
    enableShakeClass.value = true;
    setTimeout(() => {
      enableShakeClass.value = false;
    }, 1500);
    return;
  }
  if (!isResponseLoading.value && !preventSubmit.value) {
    threadStore.addQuestion(inputValue.value, null);
    inputValue.value = '';
  }
};

watch(promptLengthExceeded, () => dataLayer.push(promptLengthExceededEvent));
</script>

<template>
  <div :class="['small-prompt', enableShakeClass && 'shake']">
    <GrowingTextarea
      :inputValue="inputValue"
      :placeholder="translate('prompt.small.placeholder')"
      :onUpdate="handleInputChange"
      :onSubmit="submitPrompt"
    />
    <div class="submit-button-wrapper">
      <button v-if="isResponseLoading" type="submit" class="spinner-button">
        <Icon :name="IconNames.Spinner" spin />
      </button>
      <SubmitButtonWithTooltip
        v-else
        :preventSubmit="preventSubmit"
        :submitPrompt="submitPrompt"
        :tooltipTitle="tooltipTitle"
        :submitButtonIcon="IconNames.ArrowUp"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.small-prompt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: $wk-blue-tint6;
  border-radius: 0.5rem;
  outline: solid 1px $wk-gray-tint5;

  &:focus-within {
    outline: solid 2px $wk-gray-tint5;
  }

  &.shake {
    @include shake-animation;
  }

  .growing-textarea {
    flex-grow: 1;
    margin-right: 1rem;
  }

  .small-prompt-input {
    flex-grow: 1;
    max-height: 4.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: rgba(18, 18, 18, 0.38);
    line-break: anywhere;

    &:focus {
      outline: none;
    }

    &.focussed,
    &.dirty {
      color: $wk-gray-shade2;
    }
  }

  .submit-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
  }
}
</style>
