import { AxiosError, AxiosResponse } from 'axios';
import { defineStore, StoreDefinition } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import Domain, { DomainCode } from '@/models/Domain';
import { EventNoData, EventType } from '@/models/Event';
import Organisation from '@/models/Organisation';
import PermissionSet from '@/models/PermissionSet';
import Product from '@/models/Product';
import User, { UserDataInterface } from '@/models/User';
import { UserDetailsDataInterface } from '@/models/UserDetails';
import router from '@/router';
import { kb_api } from '@/services/api/api';
import AuthService from '@/services/auth/authService';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useEventMetaDataStore } from '@/stores/tracking/eventMetaData';

const userLoggedInEvent: Event<EventNoData> = new Event().setType(EventType.UserLoggedIn);

export const useUserStore: StoreDefinition = defineStore('user', () => {
  const user: Ref<User | null> = ref(null);
  const productCode: Ref<string | null> = ref(localStorage.getItem('productCode'));
  const permissions: Ref<PermissionSet | null> = ref(null);

  const isAuthenticated: ComputedRef<boolean> = computed<boolean>(() => AuthService.isAuthenticated());

  const userDetails: ComputedRef<UserDetailsDataInterface | null> = computed<UserDetailsDataInterface | null>(() => {
    return user.value ? user.value.userDetails : null;
  });

  const isFirstLogin: ComputedRef<boolean> = computed<boolean>(() => userDetails.value?.lastLoginDate === null);

  const userOrganisations: ComputedRef<Organisation[]> = computed<Organisation[]>(() => {
    return user.value && user.value.organisations ? user.value.organisations.items : [];
  });

  const selectedOrganisation: ComputedRef<Organisation | null> = computed<Organisation | null>(() => {
    if (!user.value || !user.value.organisations) return null;
    const lastOrgId: string | null = user.value.userDetails.lastLoggedInKnowledgeBaseOrganisationId || null;
    if (!lastOrgId) return user.value.organisations.items[0];
    return user.value.organisations.items.find((org): boolean => org.id === lastOrgId) || null;
  });

  const userProducts: ComputedRef<Product[]> = computed<Product[]>(() => {
    const currentOrg: Organisation | null = selectedOrganisation.value;
    if (!currentOrg) return [];
    const domains = currentOrg._getEmbedded('domains').data;
    return domains.map(
      (domain: Domain) => user.value?.products.items.find((prod): boolean => prod.code === domain.productCode) || null
    );
  });

  const userDomains: ComputedRef<Domain[]> = computed<Domain[]>(() => {
    const currentOrg: Organisation | null = selectedOrganisation.value;
    if (!currentOrg) return [];
    return currentOrg._getEmbedded('domains').data;
  });

  const isDomainPermitted = (domain: DomainCode | null): boolean =>
    userDomains.value.some((userDomain: Domain) => userDomain.productCode === domain);

  const lastChosenDomain: ComputedRef<Domain | null> = computed<Domain | null>(() => {
    if (!user.value) return null;
    const loginProduct: string | null = user.value.userDetails.lastLoggedInKnowledgeBaseProductCode;
    const availableDomains: Domain[] = userDomains.value;
    let domain = null;
    if (productCode.value) {
      domain = availableDomains.find((dom: Domain): boolean => dom?.productCode === productCode.value);
    }
    if (!domain) {
      domain = availableDomains.find((dom: Domain): boolean => dom?.productCode === loginProduct);
    }
    return domain || null;
  });

  const eventMetaData = useEventMetaDataStore();

  const fetchUserData = async (): Promise<UserDataInterface> => {
    try {
      const response: AxiosResponse = await kb_api.get('user/get-attributes');
      return response.data;
    } catch (error: unknown) {
      if ((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 401) {
        await router.push({ name: 'unauthorized' });
      } else {
        await router.push({ name: 'error' });
      }
      throw error;
    }
  };

  const sendLoginNotification = async () => {
    await kb_api.post('user/login-notify', {
      organisationId: user.value?.userDetails.lastLoggedInKnowledgeBaseOrganisationId
    });
  };

  const init = async (): Promise<void> => {
    user.value = new User(await fetchUserData());
    sendLoginNotification();
    dataLayer.push(userLoggedInEvent);
    permissions.value = selectedOrganisation.value?.permissions || null;
  };

  const setLastChosenDomainCode = (code: string): void => {
    productCode.value = code;
    localStorage.setItem('productCode', code);
    eventMetaData.add({ domain_code: code });
  };

  return {
    user,
    productCode,
    permissions,
    isAuthenticated,
    isFirstLogin,
    userDetails,
    userOrganisations,
    selectedOrganisation,
    userProducts,
    userDomains,
    lastChosenDomain,
    init,
    setLastChosenDomainCode,
    isDomainPermitted
  };
});
