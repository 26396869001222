import { createPinia } from 'pinia';
import { createApp } from 'vue';

import { initGtag } from '@/config/tracking/gtag';
import { initGtm } from '@/config/tracking/gtm';
import { initSentry } from '@/config/tracking/sentry';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import PermissionPlugin from '@/plugins/permissions';
import { useLocaleTranslator } from '@/translations';

import App from './App.vue';
import router from './router';

const { translate } = useLocaleTranslator();
const app = createApp(App);

initSentry(app);
initGtm(app, router);
initGtag(app, translate('application.title'));

useSetPageTitle();

app.use(createPinia()).use(router).use(PermissionPlugin).mount('#app');
