<script async setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { ButtonVariants } from '@schulinck/components/ui/button/Button.types';
import Button from '@schulinck/components/ui/button/Button.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import { ref } from 'vue';

import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { Breakpoint } from '@/models/Breakpoint';
import { EventNoData, EventType } from '@/models/Event';
import { Permission } from '@/models/PermissionSet';
import router from '@/router';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useThreadStore } from '@/stores/thread';
import { useLocaleTranslator } from '@/translations';

import HamburgerMenuIcon from './HamburgerMenuIcon.vue';
import SidebarButton from './SidebarButton.vue';
import ThreadsList from './ThreadsList/ThreadsList.vue';

const open = ref(false);
const threadStore = useThreadStore();
const { translate } = useLocaleTranslator();

const toggle = () => (open.value = !open.value);

const newThreadClick = () => {
  const newThreadEvent: Event<EventNoData> = new Event().setType(EventType.NewThread);
  if (window.innerWidth < Breakpoint.Xs) {
    toggle();
  }
  dataLayer.push(newThreadEvent);
  threadStore.resetThread();
  router.push({ name: 'home' });
  useSetPageTitle();
};
</script>

<template>
  <aside :class="['wk-sidebar', open && 'open']">
    <Stack :gap="0" class="wk-sidebar-contents">
      <Stack class="wk-sidebar-top" :gap="1.5" :alignItems="Position.Baseline">
        <Button class="wk-sidebar-toggle-button" :onClick="toggle" :variant="ButtonVariants.Text">
          <HamburgerMenuIcon :active="open" />
        </Button>
        <SidebarButton
          v-if="$hasPermission(Permission.ThreadCreateUser)"
          class="new-thread-button"
          :large="open"
          :onClick="newThreadClick"
          :icon="IconNames.Plus"
          :label="translate('sidebar.newThread.title')"
        />
      </Stack>
      <ThreadsList :show="open" :toggleSidebar="toggle" />
      <Stack class="wk-sidebar-bottom" :gap="0" :alignItems="Position.Baseline">
        <SidebarButton
          class="contact-button"
          :large="open"
          :variant="ButtonVariants.Ghost"
          :href="'mailto:product.schulinck@wolterskluwer.com'"
          :icon="IconNames.Mail"
          :label="translate('sidebar.contact.label')"
        />
      </Stack>
    </Stack>
  </aside>
</template>

<style scoped lang="scss">
.wk-sidebar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: $wk-blue-tint6;
  border-right: solid 1px $wk-blue-tint5;
  height: 100dvh;
  max-height: 100dvh;
  width: 4rem;
  min-width: 4rem;
  z-index: map-get($zIndexes, 'sidebar');
  transition:
    0.3s width ease-in-out,
    0.3s min-width ease-in-out,
    0.3s background-color ease-in-out;

  &.open {
    width: clamp(230px, 30%, 350px);
    min-width: clamp(230px, 30%, 350px);

    .wk-hr {
      opacity: 1;
      transition: opacity 0.3s ease-in-out 0.3s;
    }
  }

  .wk-sidebar-contents {
    height: 100%;
    min-height: 100dvh;
    position: relative;
  }

  .wk-sidebar-top,
  .wk-sidebar-bottom {
    padding: 0.8125rem 0 0.6875rem 0.75rem;
  }

  .wk-sidebar-toggle-button {
    font-size: 1.25rem;
    width: 2.5rem;
    padding: 0;
    color: $wk-blue-shade1;
    border-radius: 2rem;

    &:hover {
      background-color: $wk-blue-tint5;
    }
  }

  .wk-sidebar-bottom {
    margin-top: auto;
  }

  .wk-hr {
    opacity: 0;
    width: calc(100% + 0.75rem);
    position: relative;
    left: -0.75rem;
    transition: opacity 0.1s ease-in-out 0s;
  }

  @include mq(xs, max) {
    position: fixed;
    top: 0;
    left: 0;
    height: 4rem;
    background-color: transparent;
    border-right: none;

    &.open {
      width: 100%;
      background-color: $wk-white;
      height: 100dvh;
      max-height: 100dvh;
    }

    .wk-sidebar-toggle-button:hover {
      background-color: $wk-blue-tint5;
    }

    &:not(.open) {
      .new-thread-button,
      .wk-sidebar-bottom,
      .threadsList {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
}
</style>
