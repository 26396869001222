<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { computed } from 'vue';

import { useLocaleTranslator } from '@/translations';
const { translate } = useLocaleTranslator();

interface Props {
  query: string;
  onUpdate: (value: string) => void;
}
const props = defineProps<Props>();

const hasInput = computed(() => props.query !== '');

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  props.onUpdate && props.onUpdate(target.value);
};

const clearInput = () => {
  props.onUpdate('');
};
</script>

<template>
  <Cluster class="filter" :gap="0.5" :alignItems="Position.Center">
    <Icon :name="IconNames.Search" />
    <input :value="query" type="text" :placeholder="translate('sidebar.filter.placeholder')" @input="handleInput" />
    <Icon v-if="hasInput" class="clear" :name="IconNames.CloseCircle" @click="clearInput" />
  </Cluster>
</template>

<style lang="scss">
.filter {
  background-color: $wk-white;
  color: $wk-gray;
  font-size: 0.85rem;
  font-weight: 300;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  opacity: 0;
  width: 100%;
  max-width: calc(100% - 0.75rem);
  transition: opacity 0.3s ease-in-out;
  outline: solid 1px $wk-gray-tint5;

  &:focus-within {
    outline-width: 2px;
  }

  .wk-icon {
    width: auto;
  }

  .clear {
    cursor: pointer;
  }

  input {
    background-color: transparent;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $wk-gray;
    }
  }
}
</style>
